import Chart from 'chart.js/auto'

import { ready } from './utils'

// This function makes the background colour for out of hours different colour
let barChartBackground = (labels, itemName) => {
  if (itemName === 'Hour of day') {
    return labels.map((l, i) => (i < 18 && i > 7 ? '#377dff' : '#323232'))
  }
  return labels.map((l) => '#377dff')
}

function runCharts() {
  ;[...document.querySelectorAll('.barChart')].map((barChart) => {
    if (!barChart.dataset || typeof barChart.dataset.labels === 'undefined') {
      return
    }
    let labels = JSON.parse(barChart.dataset.labels)
    let itemName = barChart.dataset.itemname

    const data = {
      labels: labels.map((l) => l[0]),
      datasets: [
        {
          label: itemName,
          backgroundColor: barChartBackground(labels, itemName),
          borderColor: '#377dff',
          data: labels.map((l) => l[1]),
        },
      ],
    }

    const config = {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItem, data) {
                return labels[tooltipItem[0].dataIndex][2]
              },
            },
          },
        },
      },
    }

    const leadsChart = new Chart(barChart, config)
  })
  ;[...document.querySelectorAll('.callsBarChart')].map((barChart) => {
    if (!barChart.dataset || typeof barChart.dataset.labels === 'undefined') {
      return
    }
    let labels = JSON.parse(barChart.dataset.labels)
    let itemName = barChart.dataset.itemname

    const data = {
      labels: labels.map((l) => l[0]),
      datasets: [
        {
          label: itemName,
          backgroundColor: barChartBackground(labels, itemName),
          borderColor: '#377dff',
          data: labels.map((l) => l[1]),
        },
      ],
    }

    const config = {
      type: 'bar',
      data: data,
      options: {
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              title: function (tooltipItem, data) {
                return labels[tooltipItem[0].dataIndex][2]
              },
            },
          },
        },
      },
    }

    const phoneCallsChart = new Chart(barChart, config)
  })
  ;[...document.querySelectorAll('.pieChart')].map((pieChart) => {
    if (!pieChart.dataset || typeof pieChart.dataset.labels === 'undefined') {
      return
    }
    let labels = JSON.parse(pieChart.dataset.labels)

    let colors = [
      '#2085ec',
      '#72b4eb',
      '#0a417a',
      '#8464a0',
      '#cea9bc',
      '#323232',
      '#2085ec',
      '#72b4eb',
      '#0a417a',
      '#8464a0',
    ]

    const data = {
      labels: labels.map((l) => l[0]),
      datasets: [
        {
          label: 'Leads',
          data: labels.map((l) => l[1]),
          backgroundColor: labels.map((l, i) => colors[i]),
        },
      ],
    }

    const config = {
      type: 'pie',
      data: data,
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'right',
            labels: {
              generateLabels: (chart) => {
                const datasets = chart.data.datasets
                return datasets[0].data.map((data, i) => ({
                  text: `${chart.data.labels[i]} (${data})`,
                  fillStyle: datasets[0].backgroundColor[i],
                  lineWidth: 0,
                }))
              },
              padding: 5,
              boxWidth: 10,
            },
          },
        },
      },
    }

    const websiteChart = new Chart(pieChart, config)
  })
}

window.runCharts = runCharts

ready(function () {
  runCharts()
})
