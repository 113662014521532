import AwsS3 from '@uppy/aws-s3'
import Compressor from '@uppy/compressor'
import Uppy from '@uppy/core'
import ProgressBar from '@uppy/progress-bar'
import { v4 as uuidv4 } from 'uuid'

import { ready } from './utils'

function fileUpload(fileInput, position) {
  var imagePreview = document.getElementById(fileInput.dataset.previewElement)

  fileInput.style.display = 'none' // uppy will add its own file input

  var uppy = new Uppy({
    id: fileInput.id,
    autoProceed: true,
    debug: true,
    restrictions: {
      allowedFileTypes: fileInput.accept.split(','),
      maxFileSize: 15000000, // 15mb
    },
  })
    .use(Compressor, {
      maxWidth: 2000,
    })
    .use(ProgressBar, {
      target: imagePreview.parentNode,
    })

  uppy.use(AwsS3, {
    timeout: 0,
    getUploadParameters: function (file) {
      // This will ensure that the filename is correctly encoded in the URL, and prevent errors when the server tries to decode it.
      let encodedFilename = encodeURIComponent(file.name)
      return fetch('/presign?filename=' + encodedFilename, {
        // Shrine's presign endpoint
        credentials: 'same-origin', // send cookies
      }).then(function (response) {
        return response.json()
      })
    },
  })

  uppy.on('restriction-failed', (file, error) => {
    alert(error)
    // do some customized logic like showing system notice to users
  })

  uppy.on('compressor:complete', (props) => {
    console.log(props)
  })

  uppy.on('upload-success', function (file, response) {
    var uploadedFileData = {
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    }

    let forms =
      'form.edit_car, form.edit_preorder_car, form.edit_part, form.edit_stock_image_set, form.edit_comment_template'

    var url = document.querySelectorAll(forms)[0].getAttribute('new_image_url')

    $.ajax(url, {
      data: {
        photo: {
          photo: uploadedFileData,
          position: position,
        },
      },
      type: 'POST',
      dataType: 'json',
      success: function (response) {
        console.log(response)
        var hiddenInput = document.getElementById(fileInput.dataset.uploadResultElement)
        hiddenInput.remove()
        imagePreview.src = URL.createObjectURL(file.data)
        document.getElementById('submit-btn').disabled = false
      },
    })
  })

  return uppy
}

ready(function () {
  document.querySelectorAll('input.upload-file[type=file]').forEach(function (fileInput) {
    fileInput.addEventListener('change', function (event) {
      Array.from(fileInput.files).forEach(function (file) {
        // create a new copy of the resource for the selected file
        var template = document.getElementById(fileInput.dataset.template)
        var uploadList = document.getElementById(fileInput.dataset.uploadList)

        let position = 1
        // Set the position of the new photo element
        if (document.querySelectorAll('#photos-list > *').length > 0) {
          let previous_photo = document.querySelector('#photos-list > .photo-container:last-child')
          let previous_photo_position = previous_photo.getAttribute('position')
          if (previous_photo_position) {
            position = parseInt(previous_photo_position) + 1
          }
        }

        uploadList.insertAdjacentHTML(
          'beforeend',
          template.innerHTML.replace(/{{index}}/g, uuidv4())
        )
        let ele = uploadList.lastElementChild
        ele.setAttribute('position', position)

        // trigger file upload on the new resource
        var singleFileInput = uploadList.lastElementChild.querySelector('input[type=file]')
        document.getElementById('submit-btn').disabled = true
        var uppy = fileUpload(singleFileInput, position)
        try {
          uppy.addFile({ name: file.name, type: file.type, data: file })
        } catch (err) {
          uploadList.lastElementChild.remove()
          console.log(err)
        }
      })

      // remove selected files
      fileInput.value = ''
    })
  })
})
